// const makeSorter = (type = 'string', key) => (a, b) => {
// 	switch(type) {
// 		case 'string':
// 			return stringSorter(a[key], b[key]);
// 		case 'number':
// 			return numberSorter(a[key], b[key]);
// 		default:
// 			return stringSorter(a[key], b[key]);
// 	}
// };



// function stringSorter(a, b) {
// 	return ('' + String(a).toUpperCase()).localeCompare(String(b).toUpperCase());
// }


// function numberSorter(a, b) {
// 	return a - b;
// }



import moment from 'moment';

export const makeSorter = (type = 'string', key) => (a, b) => {
	/* NOTE
		this code hates null, undefined, and empty strings -- if you 
		are trying to add a column sorter be mindful of what comes from the query
	*/
	// console.log('markSorter')
	// console.log(type)
	// console.log(key)
	switch (type) {
		case 'string':
			return stringSorter(a[key], b[key]);
		case 'number':
			return numberSorter(a[key], b[key]);
		case 'date': // NOTE this isnt even used anywhere
			return dateSorter(a[key], b[key]);
		default:
			return stringSorter(a[key], b[key]);
	}
};

function stringSorter(a, b) {
	return ('' + a.toUpperCase()).localeCompare(b.toUpperCase());
}


function numberSorter(a, b) {
	return a - b;
}

function dateSorter(a, b) {
	return moment(a, 'MM/DD/YYYY') - moment(b, 'MM/DD/YYYY');
}

export default makeSorter;
