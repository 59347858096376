import { extend } from 'umi-request';
import Cookies from 'portal-cookie';
import { message } from 'antd';
import getResponseErrors from 'lib/getResponseErrors';
import store, { actions } from 'core/store';

/**@returns the .data property of call if successful */
const apiInstance = extend({
	prefix: process.env.REACT_APP_API_URL,
	timeout: 600000, // IMPORTANT
});

function requestInterceptor(url, options) {
	//console.log('requestInterceptor')
	let token = Cookies.get('authToken'),
		updateTypes = [
			'POST',
			'PUT',
		];

	if (token) {
		if (!options.headers) {
			options.headers = {};
		}
		options.headers['Authorization'] = `Bearer ${token}`;
	}

	let data,
		ignoreOptions = [
			'headers',
			'interceptors',
			'method',
			'params',
			'prefix',
			'timeout',
			'suffix',
			'credentials',
			'useCache',
			'validateCache',
			'ttl',
			'maxCache',
			'requestType',
			'parseResponse',
			'throwErrIfParseFail',
			'getResponse',
			'errorHandler',
			'cancelToken',
		];


	if (updateTypes.includes(options.method.toUpperCase())) {
		data = Object.keys(options).reduce((previous, current) => {
			if (!ignoreOptions.includes(current)) {
				previous[current] = options[current];
				delete (options[current]);
			}
			return previous;
		}, {});
	}


	return {
		url: url.replace(/([^:]\/)\/+/g, '$1'),
		options: { ...options, data, interceptors: true },
	};
}

async function responseErrorInterceptor(response, options) {
	console.error('[responseErrorInterceptor]');
	try {
		const data = await response.clone().json();
		let errors = getResponseErrors(data);

		if (response.status === 401 && data && data.logout) {
			console.log('forced log out, expired token');
			console.error('forced log out, expired token');
			store.dispatch(actions.auth.setState({ loggedIn: false, errors: errors }));
			Cookies.remove('authToken');
		}

		if (response.status !== 401 && errors.length) {
			for (let i = 0; i < errors.length; i++) {
				message.error(errors[i], (i + 1) * 2);
			}
		}

		return Promise.reject(data);
	} catch (err) {
		return Promise.reject(response);
	}
}


async function responseInterceptor(response, options) {
	const data = await response.clone().json();

	if (response.status >= 400) {
		return responseErrorInterceptor(response, options);
	}

	try {
		if (data.token) {
			Cookies.set('authToken', data.token);
		}

		if (data.messages && Array.isArray(data.messages)) {
			for (let i = 0; i < data.messages.length; i++) {
				message.info(data.messages[i], (i + 1) * 2);
			}
		}
	} catch (err) {
		console.log(err);
	}



	return data;
}





apiInstance.interceptors.response.use(responseInterceptor);
apiInstance.interceptors.request.use(requestInterceptor);


export default apiInstance;
