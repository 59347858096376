import React from 'react';
import { Col, Row, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';



const Home = () => {
	return (

		<Row className="ts_home_content_container">
			<Col xs={24}>
				<div className="ts_portal_card">
					<Typography.Title level={4}><ExclamationCircleOutlined /> Not Found</Typography.Title>
					<Typography>
						Looks like you took a wrong turn. Click the {process.env.REACT_APP_COMPANY_NAME} logo to go home, or use the navigation
						above to pick a page.
					</Typography>
				</div>
			</Col>
		</Row>

	);
};


export default Home;
