import React, { lazy } from 'react';
import { registerReduxStore } from 'core/store';
import { registerRoutes } from 'core/routes';
import { registerNavigation } from 'core/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { actions as salesOrderActions } from './SalesOrderViewer/SalesOrderViewer.redux';
import { reducers as salesOrderReducers } from './SalesOrderViewer/SalesOrderViewer.redux';

const SalesOrderViewer = lazy(() => import('./SalesOrderViewer/SalesOrderViewer'));

// IMPORTANT  new redux stores must be added, and they cannot be combined under the same name
registerReduxStore('salesOrders', salesOrderReducers, salesOrderActions);


registerRoutes([
    {
        path: '/sales-orders/view',
        exact: true,
        render: <SalesOrderViewer />,
        title: 'Sales Orders',
        private: true,
        permissions: [],
    },

]);

registerNavigation({
    title: 'Sales Orders',
    permissions: [],
    single: false,
    top: true,
    home: true,
    icon: <FontAwesomeIcon icon={faBoxOpen} />,
    children: [
        {
            title: 'View',
            url: '/sales-orders/view',
            permissions: [],
        },
    ],
});

