import api from 'api';
import Cookies from 'portal-cookie';
import getResponseErrors from 'lib/getResponseErrors';
import { notification } from 'antd';
import { actions as permissionActions } from './permissions.redux';

const initialState = {
	loggedIn: !!Cookies.get('authToken'),
	pending: false,
	showRelog: false,
	errors: [],
	resetToken: null,
	verifyingToken: false,
	showPasswordChange: false,
	email: Cookies.get('TS_UserEmail'),
	name: Cookies.get('TS_UserName'),
};

export const types = {
	SET_STATE: 'AUTH/SET_STATE',
};

const reducers = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default reducers;

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	handleLogin: (data) => async (dispatch, getState) => {
		console.log('[auth_v2.redux] handleLogin');
		// console.log(data)
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		try {
			let res = await api.post('/account/login', {
				email: data.username,
				password: data.password,
			});

			console.log(res);
			console.log(res.user);
			// if (res.ResetRequired == 'Yes') {
			// 	dispatch({ type: types.SET_STATE, data: { ...initialState, showPasswordChange:true } });
			// } else {
			dispatch({ type: types.SET_STATE, data: { ...initialState, loggedIn: true, email: data.username, name: res.user.Name } });
			// }
			Cookies.set('TS_User_type', res.user.TS_User_type);
			Cookies.set('TS_UserKey', res.user.TS_UserKey);
			Cookies.set('authToken', res.token);
			Cookies.set('TS_UserName', res.user.Name);
			Cookies.set('TS_UserEmail', data.username);
		} catch (err) {
			notification.destroy();
			notification['error']({
				duration: 1,
				message: 'Login failure!',
				description:
					'Invalid Credential',
			});
			console.log('err', err);
			dispatch({ type: types.SET_STATE, data: { pending: false, errors: getResponseErrors(err) } });
		}
	},

	heartbeat: (data) => async (dispatch, getState) => {
		console.log('[auth_v2.redux] heartbeat');
		try {
			let heartbeat = await api.get('/heartbeat/heartbeat');

			// console.log(heartbeat);
			// console.log(heartbeat.alive);

			if (heartbeat && heartbeat.alive) {
				console.log('SUCCESS service is alive');
				notification.destroy();
				notification['success']({
					duration: 10,
					message: 'Account Service connection: ',
					description:
						'Established',
				});
			} else {
				throw ('Account Service is not reachable');
			}

		} catch (err) {
			console.error('service is NOT alive');
			console.error(err);
			notification.destroy();
			notification['error']({
				duration: 10,
				message: 'Error: Account Service is not reachable',
				description:
					'Contact your IT or System Admin',
			});
		}
	},


	handlePasswordChange: (data) => async (dispatch, getState) => {
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		if (data.password_reset1 !== data.password_reset2) {
			return dispatch({
				type: types.SET_STATE,
				data: { pending: false, errors: ['Password fields must match.'] },
			});
		}

		try {
			await api.post('/user/change_password', {
				token: getState().auth.resetToken,
				pass: data.password_reset1,
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					pending: false,
					errors: [],
					loggedIn: false,
					showPasswordChange: false,
					showRelog: true,
					resetToken: null,
				},
			});


		} catch (err) {
			dispatch({ type: types.SET_STATE, data: { pending: false, errors: getResponseErrors(err) } });
		}
	},



	// verifyToken_OG: () => async (dispatch) => {
	// 	console.log('verifyToken_OG'); 

	// 	dispatch({ type: types.SET_STATE, data: { pending: true, loggedIn: false, verifyingToken: true, errors: [] } });

	// 	try {
	// 		await api.get('/account/verify_token');

	// 		//dispatch(permissionActions.setState(res.permissions || {}));

	// 		console.log(Cookies.get('TS_UserName'));
	// 		console.log(Cookies.get('TS_UserEmail'));
	// 		console.log(Cookies.get('authToken'));

	// 		dispatch({
	// 			type: types.SET_STATE,
	// 			data: {
	// 				...initialState,
	// 				loggedIn: true,
	// 				verifyingToken: false,
	// 			},
	// 		});

	// 	} catch (err) {
	// 		dispatch({
	// 			type: types.SET_STATE,
	// 			data: { verifyingToken: false, pending: false, errors: getResponseErrors(err) },
	// 		});
	// 		// Cookies.remove('authToken');
	// 		// Cookies.remove('TS_UserName');
	// 		// Cookies.remove('TS_UserEmail');
	// 	}
	// },

	verifyToken: () => async (dispatch) => {

		console.log('[auth] verifyToken');

		dispatch({ type: types.SET_STATE, data: { pending: true, loggedIn: false, verifyingToken: true, errors: [] } });

		try {
			let res = await api.get('/account/verify_token');
			console.log('res.token');
			console.log(res.token);
			Cookies.set('authToken', res.token);

			dispatch(permissionActions.setState(res.permissions || {}));
			dispatch({
				type: types.SET_STATE,
				data: {
					//...initialState,
					loggedIn: true,
					verifyingToken: false,
				},
			});

		} catch (err) {
			console.log('[auth] verifyToken catch err');
			dispatch({
				type: types.SET_STATE,
				data: { verifyingToken: false, pending: false, errors: getResponseErrors(err) },
			});
			Cookies.remove('authToken');
		}
	},
};
