const initialState = {
	unread:      [],
	recent:      [],
	unreadCount: 0,
	recentCount: 0,
	visible:     false,
};


export const types = {
	SET_STATE:        'NOTIFICATIONS/SET_STATE',
	ADD_NOTIFICATION: 'NOTIFICATIONS/ADD_NOTIFICATION',
};




const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };

		case types.ADD_NOTIFICATION:
			return {
				...state,
				unread:      [
					action.data,
					...state.unread, 
				],
				recent:      [
					action.data,
					...state.recent, 
				],
				unreadCount: state.unread.length + 1,
				recentCount: state.recent.length + 1,
			};
		default:
			return state;
	}
};

export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	addNotification: (data) => ({ type: types.ADD_NOTIFICATION, data }),
};
