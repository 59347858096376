import api from 'api';
import { message } from 'antd';



let userData = {
	user_id:     null,
	first_name:  '',
	last_name:   '',
	username:    '',
	force_reset: 'Y',
	active:      true,
};

const initialState = {
	users:        [],
	loadingUsers: false,
	loadedUsers:  false,

	showUserDialog:        false,
	userDialogKey:         null,
	userDialogLoaded:      false,
	userDialogLoading:     false,
	userDialogAllowDelete: false,
	userDialogData:        { ...userData },

	showPermissionsDialog:           false,
	permissionsDialogKey:            null,
	permissionsDialogLoaded:         false,
	permissionsDialogLoading:        false,
	permissionsDialogGroups:         [],
	permissionsDialogGroupsSelected: [],
	permissionsDialogPermissions:    [],
	permissionsDialogTargetSelected: [],
	permissionDialogAllSelected:     [],

	showUserDataDialog:    false,
	userDataDialogKey:     null,
	userDataDialogLoaded:  false,
	userDataDialogLoading: false,
	userDataValues:        [],
	userDataDefinitions:   [],
	userDataNewProperty:   '',
};


export const types = {
	SET_STATE: 'USERMANAGEMENT/SET_STATE',
};




const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),



	reset: () => ({ type: types.SET_STATE, data: { ...initialState } }),


	resetUserModal: () => ({
		type: types.SET_STATE, data: {
			showUserDialog:        false,
			userDialogKey:         null,
			userDialogLoaded:      false,
			userDialogLoading:     false,
			userDialogAllowDelete: false,
			userDialogData:        { ...userData },
		},
	}),


	resetPermissionsModal: () => ({
		type: types.SET_STATE, data: {
			showPermissionsDialog:           false,
			permissionsDialogKey:            null,
			permissionsDialogLoaded:         false,
			permissionsDialogLoading:        false,
			permissionsDialogGroups:         [],
			permissionsDialogGroupsSelected: [],
			permissionsDialogPermissions:    [],
			permissionsDialogTargetSelected: [],
			permissionDialogAllSelected:     [],
		},
	}),


	resetUserDataModal: () => ({
		type: types.SET_STATE, data: {
			showUserDataDialog:    false,
			userDataDialogKey:     null,
			userDataDialogLoaded:  false,
			userDataDialogLoading: false,
			userDataValues:        [],
			userDataDefinitions:   [],
			userDataNewProperty:   '',
		},
	}),



	loadUsers: () => async (dispatch, getState) => {
		const loading = getState().userManagement.loadingUsers;

		if(loading) {
			return false;
		}

		dispatch(actions.setState({ loadingUsers: true, loadedUsers: false }));

		try {
			let response = await api.get('/admin/users');

			dispatch({
				type: types.SET_STATE,
				data: {
					loadingUsers: false,
					loadedUsers:  true,
					users:        response.users,
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					loadingUsers: false,
					loadedUsers:  true,
					users:        [],
				},
			});
		}
	},



	loadUserPermissions: (userID) => async (dispatch, getState) => {
		const loading = getState().userManagement.permissionsDialogLoading;

		if(loading) {
			return false;
		}

		dispatch(actions.setState({ permissionsDialogLoading: true, permissionsDialogLoaded: false }));

		try {
			let response = await api.get(`/admin/user-permissions/${userID}`);

			dispatch({
				type: types.SET_STATE,
				data: {
					permissionsDialogLoading:        false,
					permissionsDialogLoaded:         true,
					permissionsDialogGroups:         response.groups,
					permissionsDialogGroupsSelected: response.user_groups,
					permissionsDialogPermissions:    response.permissions,
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					permissionsDialogLoading:        false,
					permissionsDialogLoaded:         true,
					permissionsDialogGroups:         [],
					permissionsDialogGroupsSelected: [],
					permissionsDialogPermissions:    [],
				},
			});
		}

	},



	loadUserData: (userID) => async (dispatch, getState) => {
		const loading = getState().userManagement.userDataDialogLoading;

		if(loading) {
			return false;
		}

		dispatch(actions.setState({ userDataDialogLoading: true, userDataDialogLoaded: false }));

		try {
			let response = await api.get(`/admin/user-data/${userID}`);

			dispatch({
				type: types.SET_STATE,
				data: {
					userDataDialogLoaded:  true,
					userDataDialogLoading: false,
					userDataValues:        response.user_data,
					userDataDefinitions:   response.definitions,
				},
			});
		} catch(err) {
			console.error(err);
			dispatch({
				type: types.SET_STATE,
				data: {
					userDataDialogLoaded:  true,
					userDataDialogLoading: false,
					userDataValues:        [],
					userDataDefinitions:   [],
				},
			});
		}
console.log(`userID: ${userID}`);
	},



	addUserDataProperty: propertyName => (dispatch, getState) => {
		let state         = getState().userManagement,
			values        = state.userDataValues,
			defs          = state.userDataDefinitions,
			existingValue = values.find(row => row.data_key === propertyName),
			existingDef   = defs.find(row => row.data_key === propertyName);

		if(existingValue) {
			message.error(`Property ${propertyName} already exists.`);
			return false;
		}

		let newValue = {
			data_key: propertyName,
			value:    '',
			type:     'string',
		};

		if(existingDef) {
			newValue.type = existingDef.data_type;
		}

		dispatch(actions.setState({
			userDataValues:      [
				...values,
				newValue, 
			],
			userDataNewProperty: '',
		}));
	},



	setPermissionStatus: (id, value) => (dispatch, getState) => {
		let permissions = getState().userManagement.permissionsDialogPermissions;

		dispatch(actions.setState({
			permissionsDialogPermissions: [
				...permissions.slice(0, id),
				{ ...permissions[id], user_value: value },
				...permissions.slice(id + 1),
			],
		}));
	},



	reorderTargetGroups: direction => (dispatch, getState) => {
		let selected = getState().userManagement.permissionsDialogGroupsSelected.map((key, index) => ({
			key:   key,
			index: index,
		}));

		let toMove = getState().userManagement.permissionsDialogTargetSelected;

		let reordered = selected.map(row => {
			let index = direction === 'up'
				? row.index + 1
				: row.index - 1;

			if(toMove.includes(row.key)) {
				index = direction === 'up'
					? row.index - 1
					: row.index + 1;
			}

			return {
				...row,
				index: index,
			};
		});

		reordered = reordered.sort((a, b) => (a.index - b.index));
		reordered = reordered.map(row => row.key);

		dispatch(actions.setState({
			permissionsDialogGroupsSelected: reordered,
		}));
	},




	saveUserPermissions: () => async (dispatch, getState) => {
		let permissionValues = [
			'Y',
			'N', 
		];

		let groups      = [ ...getState().userManagement.permissionsDialogGroupsSelected ],
			permissions = getState().userManagement.permissionsDialogPermissions
				.filter(row => permissionValues.includes(row.user_value))
				.reduce((accumulator, currentValue) => {
					accumulator[currentValue.permission_key] = currentValue.user_value;
					return accumulator;
				}, {});


		dispatch(actions.setState({
			permissionsDialogLoading: true,
		}));

		try {
			await api.put('/admin/user-permissions', {
				user_id:     getState().userManagement.permissionsDialogKey,
				groups:      groups,
				permissions: permissions,
			});

			dispatch(actions.resetPermissionsModal());
		} catch(err) {
			dispatch(actions.setState({
				permissionsDialogLoading: false,
			}));
		}
	},



	saveUserData: (key, userKey) => async (dispatch, getState) => {
		let data = getState().userManagement.userDataValues.find(row => row.data_key === key);

		if(!data) {
			message.error('Could not find value');
			return false;
		}

		try {
			await api.put('/admin/user-data', {
				user_id:    userKey,
				data_key:   data.data_key,
				data_type:  data.type,
				data_value: data.value,
			});
		} catch(err) {
			console.error(err);
		}
	},


	deleteUserData: (key, userKey) => async (dispatch, getState) => {
		let values = getState().userManagement.userDataValues,
			index  = values.findIndex(row => row.data_key === key);

		if(!index === -1) {
			message.error('Could not find value');
			return false;
		}

		try {
			await api.delete(`/admin/user-data/${userKey}/${key}`, {
				user_id:  userKey,
				data_key: key,
			});

			let newValues = [
				...values.slice(0, index),
				...values.slice(index + 1), 
			];

			dispatch(actions.setState({
				userDataValues: newValues,
			}));
		} catch(err) {
			console.error(err);
		}
	},



	setUserData: (key, data) => (dispatch, getState) => {
		let userData = getState().userManagement.userDataValues,
			index    = userData.findIndex(row => row.data_key === key),
			newRow   = {
				...userData[index],
				...data,
			},
			rows     = [
				...userData.slice(0, index),
				newRow,
				...userData.slice(index + 1),
			];

		dispatch(actions.setState({
			userDataValues: rows,
		}));
	},


};
