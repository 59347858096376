import React, { useCallback, useEffect } from 'react';
import { Alert, Button, Card, Divider, Form, Input } from 'antd';
import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'core/store';
import Cookies from 'portal-cookie';



const Login = () => {
	const dispatch = useDispatch();
	const [ form ] = Form.useForm();

	useEffect(() => {
		dispatch(actions.auth.heartbeat());
	}, []);

	const handleLogin = useCallback((values) => {
		dispatch(actions.auth.handleLogin(values));
	}, [ dispatch ]);

	const handlePasswordChange = useCallback((values) => {
		dispatch(actions.auth.handlePasswordChange(values));
	}, [ dispatch ]);

	const verifyToken = useCallback((token) => {
		dispatch(actions.auth.verifyToken(token));
	}, [ dispatch ]);

	const pending            = useSelector(state => state.auth.pending),
		  showPasswordChange = useSelector(state => state.auth.showPasswordChange),
		  showRelog          = useSelector(state => state.auth.showRelog),
		  verifyingToken     = useSelector(state => state.auth.verifyingToken),
		  errors             = useSelector(state => state.auth.errors);

	const authToken = Cookies.get('authToken');

	useEffect(() => {
		console.log('[Login.js] authToken change')
		if(authToken && !showPasswordChange && !showRelog) {
			verifyToken(authToken);
		}
	}, [
		authToken,
		verifyToken,
		showPasswordChange,
		showRelog, 
	]);

	useEffect(() => {
		if(showRelog) {
			form.resetFields([ 'password' ]);
		}
	}, [
		showRelog,
		form, 
	]);


	if(verifyingToken) {
		return null;
	}


	return (
		<div className="ts_home_content_container login_form">

			<Card title={<div><LoginOutlined /> Log In</div>} className="ts_content_container ts_portal_card">
				{showPasswordChange &&
				<Alert
					className="login_alert"
					message="You must change your password before logging in. Please set your new password below."
				/>
				}

				{showRelog &&
				<Alert
					className="login_alert"
					message="Your password has been successfully changed. Please log in again with your new password."
				/>
				}

				{errors.map(message => (
					<Alert className="login_alert" message={message} key={message} type="error" />))}
				<Form
					name="normal_login"
					className="login_form"
					initialValues={{ remember: true }}
					onFinish={showPasswordChange
						? handlePasswordChange
						: handleLogin}
					form={form}
				>
					<Form.Item
						name="username"
						rules={[ { required: true, message: 'Username is required' } ]}
					>
						<Input
							prefix={<UserOutlined className="site_form_item_icon" />}
							placeholder="User Name"
							disabled={pending || showRelog || showPasswordChange}
							type="text"
						/>
					</Form.Item>


					{!showPasswordChange &&
					<Form.Item
						name="password"
						rules={[
							{ required: true, message: 'Password is required' },
							{
								min:             10,
								message:         'Password must be at least 10 characters',
								validateTrigger: 'onSubmit',
							},
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="site_form_item_icon" />}
							placeholder="Password"
							disabled={pending}
						/>
					</Form.Item>
					}


					{showPasswordChange &&
					<Form.Item
						name="password_reset1"
						rules={[
							{ required: true, message: 'New Password is required' },
							{
								min:             10,
								message:         'Password must be at least 10 characters',
								validateTrigger: 'onSubmit',
							},
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="site_form_item_icon" />}
							type="password"
							placeholder="New Password"
							disabled={pending}
						/>
					</Form.Item>
					}

					{showPasswordChange &&
					<Form.Item
						name="password_reset2"
						rules={[
							{ required: true, message: 'New Password is required' },
							{
								min:             10,
								message:         'Password must be at least 10 characters',
								validateTrigger: 'onSubmit',
							},
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="Re-enter New Password"
							disabled={pending}
						/>
					</Form.Item>
					}

					<Divider />


					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="ts_full_width"
							loading={pending}
						>
							{showPasswordChange
								? 'Change Password'
								: 'Log In'}
						</Button>
					</Form.Item>

				</Form>
			</Card>
		</div>

	);
};

export default Login;
