import 'plugins';
import 'styles/App.sass';
import 'styles/overrides.less';

import { Provider } from 'react-redux';
import React from 'react';
import Router from './Router';
import store from 'core/store';

const App = () => (
	<Provider store={store}>

		<Router />

	</Provider>
);


export default App;
