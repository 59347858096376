export const regexpSearch = (input, testValue) => {
    let escapedValue = escapeRegExp(input);

    let re = new RegExp('^.*' + escapedValue + '.*', 'gi');
    if (re.test(testValue)) {
        return true;
    } else {
        return false;
    }
};

export const tableSearchHelper = (base, columns, searchValue) => {
    let dataSource = [];
    if (searchValue === '') {
        dataSource = [...base];
    } else {
        for (let row of base) {
            for (let col of columns) {
                if (regexpSearch(searchValue, row[col.dataIndex])) {
                    dataSource.push(row);
                    break;
                }
            }
        }
    }
    return dataSource;
};

function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
