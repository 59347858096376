import api from 'api';
import Cookies from 'js-cookie';
import makeSorter from 'lib/makeSorter';
import { regexpSearch } from 'lib/searchFns';
import { result } from 'lodash';

// const dateColumns = [
//     'est_arrival_date',
// ];

const initialState = {
    currentEditRow: null,
    salesOrders: [],
    loading: false,
    currentDataSource: [],
    columns: [],
    filteredSource: [],
    searchedSource: [],
    sortOrder: false,
    sortField: false,
    //filters: [],
    //requestedPage: 0,
    forceReload: false,
    filterValues: {
        // order_date_formatted: null,
        // event_date: null,
        // status: null,
    },
};

export const types = {
    SET_STATE: 'INCOMING/SET_STATE',
    SET_FILTER_VALUES: 'INCOMING/SETFILTERVALUES',
    START_LOADING: 'INCOMING/START_LOADING',
    CHANGE_FILTERS: 'INCOMING/CHANGE_FILTERS',
};

export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_STATE:
            return { ...state, ...action.data };

        case types.SET_FILTER_VALUES:
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    ...action.data,
                },
            };

        case types.START_LOADING:
            return {
                ...state,
                //requestedPage: action.data.requestedPage,
                take: action.data.take,
            };

        case types.CHANGE_FILTERS:
            return {
                ...state,
                forceReload: true,
                requestedSkip: 0,
                allOrders: [],
                filters: action.data.filters,
            };

        default:
            return state;
    }
};




export const actions = {
    setState: (data) => ({ type: types.SET_STATE, data }),

    setFilterValues: (data) => ({ type: types.SET_FILTER_VALUES, data }),

    startLoading: (data) => ({ type: types.START_LOADING, data }),

    setCurrentEditRow: (row) => async (dispatch, getState) => {
        // console.log('[SalesOrderViewer.redux.js] setCurrentEditRow');
        // console.log(row);
        dispatch({
            type: types.SET_STATE,
            data: {
                currentEditRow: row,
            },
        });
    },

    getSalesOrders: () => async (dispatch, getState) => {
        console.log('[SalesOrderViewer.redux.js] getSalesOrders()');
        dispatch(actions.setState({ loading: true }));

        try {
            let result = await api.get('/warehouse/sales-orders');
            //console.log(result)
            //console.log(result.salesOrders.recordset)
            let _result = result.salesOrders.recordset;
            //console.log(typeof _result)
            // console.log(typeof _result[0])

            const _arrayResult = [];
            for (let [key, value] of Object.entries(_result)) {
                //console.log(key, value);
                _arrayResult.push(value);
            }
            //console.log(_arrayResult)
            //console.log(typeof _arrayResult)

            dispatch(actions.setState({
                // salesOrders: [..._obj],
                // currentDataSource: [..._obj],
                // filteredSource: [..._obj],
                salesOrders: _arrayResult,
                currentDataSource: _arrayResult,
                filteredSource: _arrayResult,
            }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    searchCurrent: (searchValue) => (dispatch, getState) => {
        // console.log('[SalesOrderViewer.redux] searchCurrent searchValue ' + searchValue);

        let { filteredSource, sortOrder, sortField } = getState().salesOrders;
        //console.log(filteredSource)
        //console.log(typeof filteredSource[0])
        let searchedSource = [];
        if (searchValue === '') {
            searchedSource = filteredSource;
        } else {
            for (let bp of filteredSource) {
                //console.log(bp)
                for (let prop in bp) {
                    //console.log(prop)
                    // skip searching for docEntry + docKey to avoid customer confusion 
                    if (prop === 'DocEntry') {
                        //console.log('SKIP DocEntry');
                        continue;
                    }
                    if (prop === 'docKey') {
                        //console.log('SKIP docKey');
                        continue;
                    }
                    // if (prop === 'U_MagentoOrderNumber') {
                    //     //console.log('SKIP U_MagentoOrderNumber');
                    //     continue;
                    // }
                    // TODO_SEARCH does not work with dates or status
                    if (regexpSearch(searchValue, bp[prop])) {
                        searchedSource.push(bp);
                        break;
                    }
                }
            }
        }

        dispatch(actions.setState({
            searchedSource: [...searchedSource],
            searchValue,
            hasFilterBeenApplied: true,
            hasOrder: true,
        }));

        dispatch(actions.sortCurrent({
            field: sortField,
            order: sortOrder,
        }));
    },

    sortCurrent: ({ field, order }) => (dispatch, getState) => {
        //console.log('[SalesOrderViewer.redux] sortCurrent ')
        //console.log(field)
        //console.log(order)
        let { searchedSource, columns } = getState().salesOrders;

        const numberSortColumns = [
            //...dateColumns,
            'card_code',
        ];

        let newColumns = columns.map((col) => {
            if (col.dataIndex === field) {
                return { ...col, sortOrder: order };
            } else {
                return { ...col, sortOrder: false };
            }
        });

        let salesOrders = searchedSource; // IMPORTANT

        if (field !== false) {
            let type = 'string';
            if (numberSortColumns.indexOf(field) !== -1) {
                type = 'number';
            }
            salesOrders = searchedSource.sort(makeSorter(type, field));
        }

        if (order === 'ascend') {
            salesOrders = salesOrders.reverse();
        }

        dispatch(actions.setState({
            columns: newColumns,
            salesOrders: [...salesOrders],
            sortOrder: order,
            sortField: field,
        }));
    },

    getSalesOrderPreview: (record) => async (dispatch, getState) => {
        //console.log('[SalesOrderViewer.redux.js] getSalesOrderPreview()');
        dispatch(actions.setState({ loading: true }));

        // console.log(record.docKey);

        try {
            let _fullname = Cookies.get('TS_UserName');
            
            // let prvwData = await api.get(`/warehouse/sales-orders/preview/${record.docKey}/${_fullname}/${record.frozenFor}`, {
            //     params: {
            //         docKey: record.docKey, // NOTE includes DC^####
            //         fullName: _fullname,
            //         frozenFor: record.frozenFor
            //     },
            // });
            //console.log(prvwData);

            let prvwData = await api.post('/warehouse/sales-orders/preview/', {
                docKey: record.docKey, // NOTE includes DC^####
                fullName: _fullname,
                frozenFor: record.frozenFor
            });
            //console.log(prvwData);

            dispatch(actions.setState({
                loading: false,
                picturesLoading: false,
                modalOpen: true,
                previewAttachments: prvwData.b64,
                previewTitle: record.DocNum, // a text string for the UI to show the user on PDFPreviewModal
            }));

        } catch (err) {
            // TODO_PDF_ERROR
            console.error('Could not load preview');
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    updateSalesOrder: () => async (dispatch, getState) => {
        //console.log('[SalesOrderViewer.redux.js] updateSalesOrder()');
        const state = getState().salesOrders;
        const { currentEditRow } = state;
        //console.log(currentEditRow);

        // console.log(Cookies.get('TS_UserName'));
        // console.log(Cookies.get('TS_UserEmail'));

        dispatch(actions.setState({ loading: true }));
        try {
            let result = await api.put('/warehouse/sales-orders/update',
                {
                    docKey: currentEditRow.docKey,
                    fullName: Cookies.get('TS_UserName'),
                    //CardCode: currentEditRow.CardCode,
                });
            // console.log(result);
            // console.log(result.success);

            if (result.success) {
                console.log('success');
                // TODO should we re-query?
                dispatch(actions.getSalesOrders());
            }

        } catch (err) {
            console.error(
                (result & result.errorMsg)
                    ? result.errorMsg
                    : 'Could not Write');
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

};
