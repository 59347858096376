import Cookies from 'js-cookie';



const portalCookie = Cookies.withAttributes({
	path:   process.env.REACT_APP_COOKIE_BASE_PATH,
	domain: process.env.REACT_APP_COOKIE_BASE_DOMAIN,
	secure: process.env.REACT_APP_SECURE_COOKIE === 'TRUE',
});


export default portalCookie;
