import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'core/store';
import { routes } from 'core/routes';
import WrappedRoute from './WrappedRoute';
import Layout from 'core/Layout';
import NotFound from 'core/NotFound';



const Router = (props) => {
	return (
		<ConnectedRouter history={history}>
			<Route
				render={({ location }) => (
					<Layout>
						<Switch
							location={location}
							key={location.key}
						>
							{routes.map(config => {
								return <WrappedRoute
									{...config.route}
									extras={config.extras}
									render={config.render}
									key={config.route.path}
								/>;
							})}
							<Route component={NotFound} />
						</Switch>
					</Layout>
				)}
			/>
		</ConnectedRouter>
	);
};




export default Router;
